import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function() {
    var gallerySlide = new Swiper(".gallery-slide", {
        slidesPerView: 2,
        spaceBetween: 20,
        breakpoints: {
            1200: {
              slidesPerView: 3,
            }
        }
    });

    var postSlide = new Swiper(".index-post-list", {
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
                return '<button type="button" class="' + className + '">' + index + '</button>';
            }
        },
        modules: [Navigation, Pagination],
        breakpoints: {
            576: {
              slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1200: {
                slidesPerView: 4
            }
        }
    });

    $(".slick-next").on("click", function(){
        gallerySlide.slideNext();
    })

    //無障礙鍵盤控制
    if($("#indexPostList").length > 0){
        const indexPost = document.getElementById("indexPostList");
        const allIndexPosts = indexPost.querySelectorAll('.swiper-slide');

        indexPost.addEventListener('keydown', function(event) {
            if (event.key === 'ArrowLeft') {
                postSlide.slidePrev();
            }else if(event.key === 'ArrowRight' || event.key === 'Tab'){
                postSlide.slideNext();
            }
        });
    }

    if($("#relItem").length > 0){
        new Swiper("#relItem", {
            slidesPerView: 2,
            spaceBetween: 20,
            breakpoints: {
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 4
                }
            }
        });
    }

    if($("#motoImgs").length > 0){
        const motoModal = document.getElementById('motoModal')
        motoModal.addEventListener('show.bs.modal', function (event) {
            const motoImgs = new Swiper("#motoImgs", {
                slidesPerView: 1,
                spaceBetween: 0,
                initialSlide: 0,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<button type="button" class="' + className + '">' + index + '</button>';
                    }
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                modules: [Navigation, Pagination]
            });
        })
    }

    if($("#trafficControlImgs").length > 0){
        let trafficStart = 0;
        const trafficModal = document.getElementById('trafficControlModal')
        trafficModal.addEventListener('show.bs.modal', function (event) {
            trafficStart = $(event.relatedTarget).data('start');

            const trafficImgs = new Swiper("#trafficControlImgs", {
                slidesPerView: 1,
                spaceBetween: 0,
                initialSlide: trafficStart,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<button type="button" class="' + className + '">' + index + '</button>';
                    }
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                modules: [Navigation, Pagination]
            });
        })
    }

    if($("#shuttleImgs").length > 0){
        let shuttleStart = 0;

        const shuttleModal = document.getElementById('shuttleModal')
        shuttleModal.addEventListener('show.bs.modal', function (event) {
            const shuttleImgs = new Swiper("#shuttleImgs", {
                slidesPerView: 1,
                spaceBetween: 0,
                initialSlide: shuttleStart,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<button type="button" class="' + className + '">' + index + '</button>';
                    }
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                modules: [Navigation, Pagination]
            });
        })
    }
});